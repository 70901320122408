.text-rules {
    display: flex;
    width: auto;
    height: auto;
    padding: 24px 16px 36px;
    gap: 10px;
    margin: 40px 16px 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* border:solid; */

}

.text-rules-font {
    width: auto;
    height: auto;
    flex-grow: 0;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.28px;
    text-align: left;
    color: #757575;
}

.headerContainer {
    width: 100%;
    height: 24px;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    border: solid 1px var(--black-15);
    background-color: var(--white-96);
}