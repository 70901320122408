:root {
  --black-100: #222;
  --orange-100: #ff5519;
  --black-15: rgba(34, 34, 34, 0.15);
  --white-100: #fff;
  --gray-100: #757575;
  --light-gray-100: #f4f4f4;
  --black-50: rgba(34, 34, 34, 0.5);
  --white-96: rgba(255, 255, 255, 0.96);
  --gray-10: rgba(117, 117, 117, 0.1);
  --black-90: rgba(34, 34, 34, 0.9);
  --orange-30: rgba(255, 85, 25, 0.3);
  --orange-50: rgba(255, 85, 25, 0.5);
  --label-color-light-primary: #000;
  --separator-color-light-with-transparency: rgba(60, 60, 67, 0.36);
  --default-system-blue-light: #007aff;

}

/* Custom Fonts */
@font-face {
  font-family: "Pretendard-Black";
  src: url("./fonts/Pretendard-Black.woff");
}

@font-face {
  font-family: "Pretendard-Bold";
  src: url("./fonts/Pretendard-Bold.woff");
}

@font-face {
  font-family: "Pretendard-ExtraBold";
  src: url("./fonts/Pretendard-ExtraBold.woff");
}

@font-face {
  font-family: "Pretendard-ExtraLight";
  src: url("./fonts/Pretendard-ExtraLight.woff");
}

@font-face {
  font-family: "Pretendard-Light";
  src: url("./fonts/Pretendard-Light.woff");
}

@font-face {
  font-family: "Pretendard-Medium";
  src: url("./fonts/Pretendard-Medium.woff");
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("./fonts/Pretendard-Regular.woff");
}

@font-face {
  font-family: "Pretendard-SemiBold";
  src: url("./fonts/Pretendard-SemiBold.woff");
}

@font-face {
  font-family: "Pretendard-Thin";
  src: url("./fonts/Pretendard-Thin.woff");
}

@font-face {
  font-family: "Pretendard";
  src: url("./fonts/PretendardVariable.woff2");
}

.Large-Title {
  font-family: 'Pretendard';
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.48px;
}

.Title {
  font-family: 'Pretendard';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
}

.Headline {
  font-family: 'Pretendard';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.36px;
}

.Placeholder {
  font-family: 'Pretendard';
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.34px;
}

.Large-Lable {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.Headline-2 {
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.32px;
}

.banner_title {
  font-family: 'Pretendard';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.Body-1 {
  font-family: 'Pretendard';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.3px;
}

.Text-Style {
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.28px;
  text-align: left;
  color: var(--white-100);
}

.Placeholder-2 {
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.28px;
}

.Body-2 {
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
}

.Footnote {
  font-family: 'Pretendard';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
}

.banner_subtext {
  font-family: 'Pretendard';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.24px;
}

.small_text {
  font-family: 'Pretendard';
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.22px;
}