.text-title-box {
    height: 100px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    padding: 32px 16px 24px;
    background-color: white;
}

.text-title {
    height: 29px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.48px;
    text-align: left;
    color: var(--black-100);
}

.text-subtext {
    width: 390px;
    height: 40px;
    font-family: Pretendard;
    color: rgba(34, 34, 34, 0.5);
    text-align: left;
    font-size: 14px;
    flex-grow: 0;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.28px;
    line-height: 1.43;
}

.tab-text-box {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    padding: 18px 16px;
    background-color: white;
    cursor: pointer;
}

.tab-text {

    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.tab-text-box2 {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    padding: 18px 16px;
    background-color: white;
    border: solid 1px rgba(34, 34, 34, 0.15);
}

.text-solution {
    height: 29px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.48px;
    text-align: left;
    color: #ff5519;
}

.tab-text-box3 {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    padding: 18px 16px;
    background-color: white;
    border-bottom: solid 5px rgba(34, 34, 34, 0.15);
}

.button-box {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-radius: 4px;
    border: solid 1px var(--black-15);
    background-color: var(--white-100);
}

.complainButton {
    /* width: 85%;
    left: 50%;
    transform: translateX(-50%);
    position: fixed; */
    flex-grow: 1;
    height: 19px;
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 4px;
    border: solid 1px var(--black-15);
    background-color: var(--white-100);
    color: var(--black-100);
}