.message {
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  display: flex;
  resize: none;
  margin-top: 12px;
  padding: 9px 10px;
  border-radius: 4px;
  border: solid 0.5px var(--black-15);
  background-color: var(--light-gray-100);
}

.btnSubmit {
  flex-grow: 1;
  height: 19px;
  margin: 0 16px 24px;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 4px;
  background-color: var(--orange-100);
  color: var(--white-100);
}

.check-position {
  width: 17px;
  height: 17px;
  margin: 0;
  margin-left: 15px;
  margin-right: 5px;
  padding-top: 2px;
  justify-content: center;
}

.text-address {
  height: 42px;
  flex-grow: 1;
  margin: 0 16px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 9px 10px;
  opacity: 0.5;
  border-radius: 4px;
  border: solid 1px rgba(34, 34, 34, 0.15);
  background-color: var(--white-100);
}