.smokeTab {
    height: 94px;
    flex-grow: 1;
    display: flex;
    margin: 0 10px;
    padding: 16px;
    z-index: 2;
    padding: 16px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px var(--black-15);
    background-color: var(--white-96);
}

.textContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.btnContainer {
    gap: 8px;
    width: calc(100% - 16px);
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.nonsmokeBadge {
    width: 27px;
    height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: solid 1px var(--gray-100);  
    color: var(--gray-100);
}

.btnNavi {
    height: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 11px 9px;
    border-radius: 100px;
    box-shadow: 0 4px 10px 0 rgba(255, 85, 25, 0.1);
    background-color: var(--orange-100);
    border: solid 1px var(--orange-100);
    color: var(--white-100);
}

.btnHelp {
    height: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 11px 9px;
    border-radius: 100px;
    border: solid 1px var(--black-15);
    background-color: var(--white-100);
    color: var(--black-100);
}