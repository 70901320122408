.header {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px;
    border: 1px solid var(--black-15);
}

#cbMenu {
    display: none;
}

#cbMenu+label {
    position: relative;
    display: block;
    cursor: pointer;
    z-index: 5;
}

#sideMenu {
    position: fixed;
    width: 270px;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--white-100);
    right: -270px;
    transition: all 300ms;
    z-index: 3;
    top: 0px;
    box-sizing: border-box;
}

#cbMenu:checked+label {
    content: url('../../public/images/ic_exit.svg');
}

#cbMenu:checked+label+#sideMenu {
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 9999px;
    z-index: 4;
}

.tabProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    flex-direction: column;
    width: 100%;
    height: 80px;
    padding: 36px 0 24px 0;
    border-bottom: solid 1px var(--black-15);

    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: var(--black-100);
}

.profilePicture {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: solid 1.3px var(--black-15);
}

.tab {
    width: 100%;
    align-items: center;
    height: 24px;
    padding: 12px 20px;
    display: flex;
    border-bottom: solid 1px var(--black-15);
    background-color: var(--white-100);

    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.3px;
    text-align: left;
}

.tabIcon {
    width: 24px;
    height: 24px;
    padding-right: 12px;
}

.menuFooter {
    width: calc(100% - 40px);
    bottom: 16px;
    position: absolute;
    display: flex;
    gap: 8px;
    padding: 0px 20px;
    background-color: var(--white-100);
}

.btnDownload {
    height: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 9px;
    border-radius: 100px;
    box-shadow: 0 4px 10px 0 rgba(255, 85, 25, 0.1);
    background-color: var(--orange-100);

    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: left;
    color: var(--white-100);
}

.btnContact {
    height: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 9px;
    border-radius: 100px;
    border: solid 1px var(--black-15);
    background-color: var(--white-100);

    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: left;
    color: var(--black-100);
}