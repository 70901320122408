.btnLocation {
    width:44px;
    height:44px;
    z-index: 2;
    right:7.25px;
    bottom:12px;
    position: fixed;
}

.bannerContainer {
    display: flex;
}

.Banner {
    height: 58px;
    width: calc(100% - 32px);
    display: flex;
    margin: 16px;
    z-index: 2;
    padding: 0 16px;
    border-radius: 4px;
    background-color: var(--black-90);
}

.banner_textContainer {
    margin: 0 0 0 11.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner_title {
    height: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px; 
    display: flex;
    letter-spacing: -0.02em;
    color: var(--white-100);
}

.banner_subtitle {
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px; 
    display: flex;
    letter-spacing: -0.02em;
    color: var(--white-100);
}