.promotionImg {
    width: 100%;
    max-width: 700px;
}

.kakaoChannelBackground {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 76px;
    position: fixed;
    bottom: 0px;
    background-image: linear-gradient(0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%);
}

.btnKakaoChannel {
    padding: 15px 24px;
    background-color: #fee500;
    width: calc(100% - 80px);
    height: 22px;
    border-radius: 6px;
    display: flex;
}