.gradientBoxDown {
    width: 100%;
    height: 180px;
    position: fixed;
    bottom: 0;
    background-image: linear-gradient(0deg,
            rgba(237, 97, 51, 0.5) -19%,
            rgba(255, 255, 255, 0) 100%);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btnHome {
    width: 85%;
    height: 19px;
    position: fixed;
    z-index: 2;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: solid 1px var(--white-100);
    background-color: var(--white-100);
    color: var(--black-100);
}