.nameTab {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: var(--white-100);
}

.profile {
    width: 64px;
    height: 64px;
    display: flex;
    margin-right: 16px;
    border-radius: 50%;
    border: solid 1.8px var(--black-15);
}

.name {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: left;
    color: #ef4b17;
}

.email {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: left;
    color: var(--black-100);
}

.divLine {
    width: 100%;
    height: 10px;
    background-color: #F4F4F4;
}

.tab {
    width: 100%;
    height: 21px;
    align-items: center;
    padding: 18px 16px;
    border-bottom: solid 1px var(--black-15);
    background-color: var(--white-100);

    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-100);
}

.lastTab {
    width: 100%;
    height: 21px;
    align-items: center;
    display: flex;
    padding: 18px 16px;
    background-color: var(--white-100);

    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-100);
}

.versionBadge {
    width: 30px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    box-sizing: border-box;
    border: solid 1px var(--gray-100);

    font-family: Pretendard;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.22px;
    text-align: center;
    color: var(--gray-100);
}